body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.w-half {
  width: 50%;
}

.scroll-component {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
}

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}

.fsw-0 {
  width: 0%;
}
.fsw-1 {
  width: 1%;
}
.fsw-2 {
  width: 2%;
}
.fsw-3 {
  width: 3%;
}
.fsw-4 {
  width: 4%;
}
.fsw-5 {
  width: 5%;
}
.fsw-6 {
  width: 6%;
}
.fsw-7 {
  width: 7%;
}
.fsw-8 {
  width: 8%;
}
.fsw-9 {
  width: 9%;
}
.fsw-10 {
  width: 10%;
}
.fsw-11 {
  width: 11%;
}
.fsw-12 {
  width: 12%;
}
.fsw-13 {
  width: 13%;
}
.fsw-14 {
  width: 14%;
}
.fsw-15 {
  width: 15%;
}
.fsw-16 {
  width: 16%;
}
.fsw-17 {
  width: 17%;
}
.fsw-18 {
  width: 18%;
}
.fsw-19 {
  width: 19%;
}
.fsw-20 {
  width: 20%;
}
.fsw-21 {
  width: 21%;
}
.fsw-22 {
  width: 22%;
}
.fsw-23 {
  width: 23%;
}
.fsw-24 {
  width: 24%;
}
.fsw-25 {
  width: 25%;
}
.fsw-26 {
  width: 26%;
}
.fsw-27 {
  width: 27%;
}
.fsw-28 {
  width: 28%;
}
.fsw-29 {
  width: 29%;
}
.fsw-30 {
  width: 30%;
}
.fsw-31 {
  width: 31%;
}
.fsw-32 {
  width: 32%;
}
.fsw-33 {
  width: 33%;
}
.fsw-34 {
  width: 34%;
}
.fsw-35 {
  width: 35%;
}
.fsw-36 {
  width: 36%;
}
.fsw-37 {
  width: 37%;
}
.fsw-38 {
  width: 38%;
}
.fsw-39 {
  width: 39%;
}
.fsw-40 {
  width: 40%;
}
.fsw-41 {
  width: 41%;
}
.fsw-42 {
  width: 42%;
}
.fsw-43 {
  width: 43%;
}
.fsw-44 {
  width: 44%;
}
.fsw-45 {
  width: 45%;
}
.fsw-46 {
  width: 46%;
}
.fsw-47 {
  width: 47%;
}
.fsw-48 {
  width: 48%;
}
.fsw-49 {
  width: 49%;
}
.fsw-50 {
  width: 50%;
}
.fsw-51 {
  width: 51%;
}
.fsw-52 {
  width: 52%;
}
.fsw-53 {
  width: 53%;
}
.fsw-54 {
  width: 54%;
}
.fsw-55 {
  width: 55%;
}
.fsw-56 {
  width: 56%;
}
.fsw-57 {
  width: 57%;
}
.fsw-58 {
  width: 58%;
}
.fsw-59 {
  width: 59%;
}
.fsw-60 {
  width: 60%;
}
.fsw-61 {
  width: 61%;
}
.fsw-62 {
  width: 62%;
}
.fsw-63 {
  width: 63%;
}
.fsw-64 {
  width: 64%;
}
.fsw-65 {
  width: 65%;
}
.fsw-66 {
  width: 66%;
}
.fsw-67 {
  width: 67%;
}
.fsw-68 {
  width: 68%;
}
.fsw-69 {
  width: 69%;
}
.fsw-70 {
  width: 70%;
}
.fsw-71 {
  width: 71%;
}
.fsw-72 {
  width: 72%;
}
.fsw-73 {
  width: 73%;
}
.fsw-74 {
  width: 74%;
}
.fsw-75 {
  width: 75%;
}
.fsw-76 {
  width: 76%;
}
.fsw-77 {
  width: 77%;
}
.fsw-78 {
  width: 78%;
}
.fsw-79 {
  width: 79%;
}
.fsw-80 {
  width: 80%;
}
.fsw-81 {
  width: 81%;
}
.fsw-82 {
  width: 82%;
}
.fsw-83 {
  width: 83%;
}
.fsw-84 {
  width: 84%;
}
.fsw-85 {
  width: 85%;
}
.fsw-86 {
  width: 86%;
}
.fsw-87 {
  width: 87%;
}
.fsw-88 {
  width: 88%;
}
.fsw-89 {
  width: 89%;
}
.fsw-90 {
  width: 90%;
}
.fsw-91 {
  width: 91%;
}
.fsw-92 {
  width: 92%;
}
.fsw-93 {
  width: 93%;
}
.fsw-94 {
  width: 94%;
}
.fsw-95 {
  width: 95%;
}
.fsw-96 {
  width: 96%;
}
.fsw-97 {
  width: 97%;
}
.fsw-98 {
  width: 98%;
}
.fsw-99 {
  width: 99%;
}
.fsw-100 {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  /* #fs_sidebar {
    width: 20% !important;
  }

  #fs_basePage {
    width: 80% !important;
  } */
}

@media only screen and (max-width: 1200px) {
  /* #fs_sidebar {
    width: 25% !important;
  }

  #fs_basePage {
    width: 75% !important;
  } */
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
}
